<template>
  <b-row>
    <b-col
      md="9"
      cols="12"
    >
      <static-cards :dashboard="dashboard" />

    </b-col>
    <b-col
      md="3"
      cols="12"
    >
      <latest-orders :latest-orders=" dashboard.latest_five_orders " />
    </b-col>

    <b-col
      class="p-0"
      md="9"
      cols="12"
    >
      <charts />

    </b-col>
    <b-col
      md="3"
      cols="12"
    >
      <latest-users :latest-users=" dashboard.latest_five_users " />
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import StaticCards from './components/StaticCards.vue'
import charts from './components/charts.vue'
import LatestOrders from './components/latestOrders.vue'
import LatestUsers from './components/latestUsers.vue'

export default {
  components: {
    StaticCards,
    charts,
    LatestOrders,
    LatestUsers,
  },

  data() {
    return {
      dashboard: {},
    }
  },
  computed: {

    ...mapGetters({ currentCountry: 'app/getCountry' }),

  },
  watch: {
    '$store.state.app.country': {
      immediate: true,
      handler() {
        this.getDahboard()
        // make actions with newVal.page
      },
    },
  },
  mounted() {
    this.getDahboard()
  },
  setup() {
    const resolveTransactinsStatusVariantAndIcon = status => {
      if (status === true) return { variant: 'light-success', icon: 'CheckCircleIcon' }

      return { variant: 'light-danger', icon: 'InfoIcon' }
    }

    const resolveTransactionsMethod = method => {
      if (method === 'received_money') return { variant: 'light-success', icon: 'ArrowDownRightIcon' }

      return { variant: 'light-info', icon: 'ArrowUpLeftIcon' }
    }

    return {
      resolveTransactinsStatusVariantAndIcon,
      resolveTransactionsMethod,
    }
  },
  methods: {
    async getDahboard(params) {
      try {
        const { data } = await axios.get(`admin/statistics?country_id=${this.currentCountry.id}`, { params })
        this.dashboard = data.data
      } catch (error) {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server Error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>

<style>

</style>
